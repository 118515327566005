const resource = {
    en: {
        heading: 'Change Password',
        old_password: 'Old Password',
        new_password: 'New Password',
        confirm_new_password: 'Confirm New Password',
        change_password: 'Change Password',
        success: 'Your password was successfully updated'
    },
    de: {
        heading: 'Passwort Ändern',
        old_password: 'Altes Passwort',
        new_password: 'Neues Passwort',
        confirm_new_password: 'Neues Passwort bestätigen',
        change_password: 'Passwort Ändern',
        success: 'Ihr Passwort wurde erfolgreich geändert'
    }
}

export default resource;