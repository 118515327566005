<template>
    <div class="dashboard-main">
        <h1 class="dashboard-main-heading" v-html="resource[lang].heading" />
        <form @submit.prevent="update">
            <InputText type="password" :label="resource[lang].old_password" v-model="old_password" requird />
            <InputText type="password" :label="resource[lang].new_password" v-model="new_password" required />
            <InputText type="password" :label="resource[lang].confirm_new_password" v-model="new_password_confirmation" required />

            <Button stretch color="primary" :label="resource[lang].change_password" submit />
        </form>
    </div>
</template>

<script>
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/change-password-resource'

import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'

import { promisePutRequest } from '@/assets/js/axios-utilities'

export default {
    components: {
        Button,
        InputText
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            // form data
            old_password: '',
            new_password: '',
            new_password_confirmation: '',

            resource
        }
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    methods: {
        // Requests
        changePasswordRequest() {
            const request_data = {
                old_password: this.old_password,
                new_password: this.new_password,
                new_password_confirmation: this.new_password_confirmation,
            }
            return promisePutRequest('/change-password', request_data);
        },

        // Actions
        async update() {
            this.$store.dispatch('loading', true)
            await this.changePasswordRequest();
            this.$store.dispatch('loading', false)

            this.old_password = ''
            this.new_password = ''
            this.new_password_confirmation = ''

            this.quasar.notify({
                position: 'bottom-left', 
                message: this.resource[this.lang].success, 
                color: 'positive'
            });
        }
    },
}
</script>

<style lang="scss" scoped>

.dashboard-main {
    max-width: 35rem;
}

form > * + * {
    margin-block-start: 1rem;
}

</style>